@font-face {
  font-family: FrutigerRoman;
  src: url('../../assets/fonts//FrutigerLT55Roman.ttf');
}

@font-face {
  font-family: FrutigerBold;
  src: url('../../assets/fonts/FrutigerLT65Bold.ttf');
}

@font-face {
  font-family: AbsideSmooth;
  src: url('../../assets/fonts/Abside-Smooth.ttf');
}

@font-face {
  font-family: PoppinsRegular;
  src: url('../../assets/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: PoppinsBold;
  src: url('../../assets/fonts/Poppins-Bold.ttf');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: FrutigerRoman, -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  min-width: 320px;
  color: #333;
}

body.hidden {
  height: 100%;
  overflow: hidden;
}

body.hidden header {
  z-index: 1;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #8dc63f;
  font-size: inherit;
  transition: 0.3s;
  font-weight: 500;
}

a:hover {
  opacity: 0.5;
}

.resources-list {
  margin-left: 40px;
  margin-top: 10px;
}

.resources-list li {
  padding: 5px 0;
}